<template>
    <v-app>
        <v-row>
            <v-col cols="3">
                <v-card flat>
                    <v-card-title class="justify-center">
                        Syllabus
                    </v-card-title>
                    <v-card-text>


                        <div class="text-center">
                            <div class="d-flex align-items-center justify-content-between mb-2" v-if="lesson">
                                <span class="font-weight-bold mr-2">Title</span>
                                <strong class="heading">{{lesson.title}}</strong>
                            </div>

<!--                            <div class="d-flex align-items-center justify-content-between mb-2" v-if="lesson && lesson.course_id">-->
<!--                                <span class="font-weight-bold mr-2">Course</span>-->
<!--                                <strong class="heading">{{lesson.course.title}}</strong>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center justify-content-between mb-2" v-if="lesson && lesson.program">-->
<!--                                <span class="font-weight-bold mr-2">Program</span>-->
<!--                                <strong class="heading">{{lesson.program.title}}</strong>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center justify-content-between mb-2" v-if="lesson">-->
<!--                                <span class="font-weight-bold mr-2">Credit Hours</span>-->
<!--                                <strong class="heading">{{lesson.credit_hour}}</strong>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center justify-content-between mb-2" v-if="lesson">-->
<!--                                <span class="font-weight-bold mr-2">Lecture Hours</span>-->
<!--                                <strong class="heading">{{lesson.lecture_hour}}</strong>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center justify-content-between mb-2" v-if="lesson &&  lesson.start_year">-->
<!--                                <span class="font-weight-bold mr-2">Start Year</span>-->
<!--                                <strong class="heading"> {{lesson.start_year}}</strong>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center justify-content-between mb-2" v-if="lesson &&  lesson.end_year">-->
<!--                                <span class="font-weight-bold mr-2">End Year</span>-->
<!--                                <strong class="heading"> {{lesson.end_year}}</strong>-->
<!--                            </div>-->
                        </div>
                        <v-tabs class="menu-parent" vertical v-model="model">
                            <v-tab>
                                <v-icon left>
                                    fas fa-book-open
                                </v-icon>
                                Topics
                            </v-tab>
                            <v-tab>
                                <v-icon left>
                                    fas fa-user-tie
                                </v-icon>
                                Activity Manager
                            </v-tab>
                            <v-tab>
                                <v-icon left>
                                    fas fa-calendar-check
                                </v-icon>
                                Lesson Planner
                            </v-tab>

                        </v-tabs>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="9">
                <v-tabs-items v-model="model">
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                              <topic></topic>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item>
                        <v-card flat>

                            <v-card-text>
                                <activity></activity>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>

                            <v-card-text>
                                <lesson-planner></lesson-planner>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>

    import  {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";
    import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";

    const lessonPlannerService = new LessonPlannerService();

    import Topic from "./topic/Index";
    import Activity from "./activity/Index";
    export default {
        name: "LessonPlannerMixin",
        mixins: [LessonPlannerMixin],
        components: {
            Topic,Activity

        },
        data() {
            return {
                model: 0,
                isLoading: false,
                lesson: null,
                search: {},
            }
        },
        computed: {
            lesson_id() {
                return this.$route.params.lesson_id;
            }
        },
        mounted() {
            this.__get()


        },
        methods: {
            __get() {
                lessonPlannerService.show(this.lesson_id).then((response) => {
                    this.lesson = response.data.lesson;
                }).catch(err => {
                    console.log(err)
                }).finally(() => {

                })
            },


        },
    }
</script>

<style scoped>
    .menu-parent .v-tab {
        justify-content: flex-start !important;
    }
</style>
