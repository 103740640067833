<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-card-title>
                    {{`${edit?'Update':'Add'}`}} Activity
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="activity.title" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.activity.title.$error">Title is required</span>

                        </v-col>
                        <div class="col-12">
                            <ckeditor v-model="activity.description"></ckeditor>
                            <span class="text-danger"
                                  v-if="$v.activity.description.$error">Description is required</span>
                        </div>


                        <v-col cols="6">
                            <v-select
                                    :items="syllabuses"
                                    v-model="activity.syllabus_id"
                                    outlined
                                    dense
                                    label="Syllabus"
                                    item-text="title"
                                    item-value="id"
                            >

                            </v-select>

                        </v-col>

                        <div class="col-3">
                            <v-switch
                                    v-model="activity.is_active"
                                    label="Status"
                            >
                            </v-switch>
                        </div>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed  @click="resetForm" class="text-gray btn btn-standard  ">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate" class="text-white ml-2 btn btn-primary ">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import ActivityService from "@/core/services/lesson-planner/activity/ActivityService";

    const activityService = new ActivityService();
    import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";

    export default {
        name:'activity',
        mixins:[LessonPlannerMixin],
        validations: {
            activity: {
                title: {required},
                description: {required},

            }
        },
        data() {
            return {
                syllabus:[],
                topics:[],
                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                search: {
                    active: "active",
                    is_parent: 1,
                },
                activity: {
                    title: null,
                    description: null,
                    syllabus_id: null,
                    is_active: true
                },
            };
        },
        computed: {
            syllabus_id() {
                return this.$route.params.syllabus_id;
            }
        },
        mounted() {
            this.getSyllabus();
        },
        methods: {
            showModal(id = null) {
                this.activity.syllabus_id = this.syllabus_id;
                if (id) {
                    this.edit = true
                    this.__get(id)
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            __get(id) {
                activityService.show(id).then(response => {
                    this.activity = response.data.activity
                })
            },

            createOrUpdate() {
                this.$v.activity.$touch();
                if (this.$v.activity.$error) {
                    setTimeout(() => {
                        this.$v.activity.$reset();
                    }, 3000);
                } else {
                    let fd = this.activity;
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                activityService
                    .update(this.activity.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            __create(fd) {
                this.isBusy = true;
                activityService
                    .store(fd)
                    .then((response) => {
                        console.log(response)
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        console.log(error);
                    });
            },
            resetForm() {
                this.$v.activity.$reset();
                this.edit = false;
                this.activity = {
                    title: null,
                    is_active: null
                };
                this.hideModal();
                this.$emit("refresh_activity");
            }
        }
    };
</script>
