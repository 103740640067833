<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <!--begin: Wizard Form-->
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <h4>
                                    <span> Manage Activity</span>
                                </h4>
                                <router-link :to="{name:'dashboard'}">
                                    Dashboard
                                </router-link>
                                \
                                <router-link :to="{name:'syllabus'}">
                                    Syllabus
                                </router-link>
                                \
                                Activity

                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 text-right">
                                <a @click="__add" class="btn btn-primary text-white mr-2">

                                    Add Activity
                                </a>


                            </div>
                        </div>
                        <div class="row">
                            <form @submit.prevent="__get" class="row mt-5 mb-5 bg-filter">
                                <div class="col-3">
                                    <v-text-field label="Title"
                                                  type="text"
                                                  dense
                                                  outlined
                                                  v-model="search.title"/>
                                </div>
                                <div class="col-4">
                                    <v-select label="Status" outlined dense :items="active_statuses" item-text="title"
                                              item-value="value"
                                              v-model="search.active"></v-select>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" @click="__get">Search</button>
                                </div>
                            </form>
                            <table class="table mt-3">
                                <thead class="text-left">
                                <th>Title</th>
                                <th>Description</th>
                                <th>Syllabus</th>
                                <th>Action</th>
                                </thead>

                                <draggable v-if="activities.length" @change="sort" v-model="activities" class="text-left"
                                           tag="tbody" handle=".handle">
                                    <tr v-for="(activity, index) of activities" :key="activity.id">
                                        <td scope="row">
                                            <i class="cursor-drag fas fa-sort handle pr-2"></i>
                                            <a href="#" @click="__edit(activity.id)" class="mr-2"> {{ activity.title }}</a>
                                            <i class="fas fa-circle"
                                               :class="activity.is_active?'dot-active':'dot-inactive'"></i>
                                        </td>
                                        <td>
                                            <p v-html="activity.description ">
                                            </p>
                                        </td>

                                        <td  v-if="activity.syllabus">
                                            {{ activity.syllabus.title }}
                                        </td>

                                        <td>
                                            <b-dropdown
                                                    size="sm"
                                                    variant="link"
                                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                    no-caret
                                                    right
                                                    no-flip
                                            >
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="__edit(activity.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link"
                                                           @click="__delete(activity.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                                            <span class="navi-text">  Delete</span>
                                                        </a>
                                                    </b-dropdown-text>

                                                </div>
                                                <!--end::Navigation-->
                                            </b-dropdown>

                                        </td>
                                    </tr>
                                </draggable>

                            </table>
                        </div>
                        <div class="row">
                            <div class="col-12" v-if="total>perPage">
                                <b-pagination
                                        @input="__get"
                                        v-model="page"
                                        :total-rows="total"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                ></b-pagination>
                                <!--  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <create-and-update ref="activity" @refresh_activity="__get"></create-and-update>
        </div>
    </v-app>
</template>
<script>

    import CreateAndUpdate from "./CreateAndUpdate";
    import draggable from 'vuedraggable'
    import ActivityService from "@/core/services/lesson-planner/activity/ActivityService";

    const activityService = new ActivityService();
    import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";

    export default {
        name: "activity",
        mixins: [LessonPlannerMixin],
        display: "Table",
        order: 8,
        components: {
            CreateAndUpdate,
            draggable
        },
        data() {
            return {
                active_statuses: [
                    {title: 'Active', value: "active"},
                    {title: 'Inactive', value: "in_active"}
                ],
                activities: [],
                page: null,
                perPage: null,
                total: null,
                search: {
                    active: "active",
                    is_parent: "null"
                },
                dragging: false
            };
        },
        mounted() {
            this.__get();

        },
        methods: {
            __get() {
                activityService
                    .paginate(this.search)
                    .then(response => {
                        this.activities = response.data.data;
                        console.log(this.activities)
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            __add() {
                this.$refs["activity"].showModal();
            },
            __edit(id) {
                this.$refs["activity"].showModal(id);
            },
            manageCategory() {
                this.$refs['manageCategory'].showModal();
            },

            __delete(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            activityService
                                .delete(id)
                                .then(response => {
                                    this.__get();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
            sort() {
                activityService.sort(this.activities).then((res) => {
                    this.$snotify.success("Sorted");
                    this.__get()
                }).catch(err => {
                    this.$snotify.success("Sorted Error");
                })
            },

        }
    };
</script>
<style scoped>
    .buttons {
        margin-top: 35px;
    }
</style>
