<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          {{ `${edit ? 'Update' : 'Add'}` }} Topic
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Title" v-model="topic.title" outlined dense>

              </v-text-field>
              <span class="text-danger" v-if="$v.topic.title.$error">Title is required</span>

            </v-col>
            <div class="col-12">
              <ckeditor v-model="topic.description"></ckeditor>
              <span class="text-danger"
                    v-if="$v.topic.description.$error">Description is required</span>
            </div>

            <v-col cols="6">
              <v-text-field label="Lecture Hours" v-model="topic.lecture_hour" outlined dense>

              </v-text-field>

            </v-col>
            <v-col cols="6">
              <v-select
                  :items="syllabuses"
                  v-model="topic.syllabus_id"
                  outlined
                  dense
                  label="Syllabus"
                  item-text="title"
                  item-value="id"
              >

              </v-select>

            </v-col>
            <div class="col-3">
              <v-switch
                  v-model="topic.is_active"
                  label="Status"
              >
              </v-switch>
            </div>
            <v-col cols="12">
              <div class="text-right float-right">
                <v-btn depressed @click="resetForm" class="text-gray btn btn-standard  ">
                  Cancel
                </v-btn>
                <v-btn depressed :loading="isBusy" @click="createOrUpdate" class="text-white ml-2 btn btn-primary ">
                  Save
                </v-btn>
              </div>

            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import TopicService from "@/core/services/lesson-planner/topic/TopicService";

const topicService = new TopicService();
import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";


export default {
  name: 'topics',
  mixins: [LessonPlannerMixin],
  validations: {
    topic: {
      title: {required},
      description: {required},

    }
  },
  computed: {
    syllabus_id() {
      return this.$route.params.syllabus_id;
    }
  },
  data() {
    return {
      dialog: false,
      edit: false,
      sizeExceed: false,
      isBusy: false,
      search: {
        active: "active",
        is_parent: 1,
      },
      topic: {
        title: null,
        topic_number: null,
        lecture_hour: null,
        syllabus_id: null,
        is_active: true
      },
    };
  },
  mounted() {
    this.getSyllabus()
  },
  methods: {
    showModal(id = null) {
      this.topic.syllabus_id = this.syllabus_id;
      if (id) {
        this.edit = true
        this.__get(id)
      }
      this.dialog = true;
      // this.$refs["my-modal"].show();
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    __get(id) {
      topicService.show(this.syllabus_id, id).then(response => {
        this.topic = response.data.topic
      })
    },

    createOrUpdate() {
      this.$v.topic.$touch();
      if (this.$v.topic.$error) {
        setTimeout(() => {
          this.$v.topic.$reset();
        }, 3000);
      } else {
        let fd = this.topic;
        if (this.edit) {
          this.__update(fd);
        } else {
          this.__create(fd);
        }
      }
    },
    __update(fd) {
      this.isBusy = true;
      topicService
          .update(this.syllabus_id, this.topic.id, fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");

            this.resetForm();
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    __create(fd) {
      this.isBusy = true;
      topicService
          .store(this.syllabus_id, fd)
          .then((response) => {
            this.isBusy = false;
            this.resetForm();
            this.$snotify.success("Information added");

          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },
    resetForm() {
      this.$v.topic.$reset();
      this.edit = false;
      this.topic = {
        title: null,
        is_active: true
      };
      this.hideModal();
      this.$emit("refresh_topic");
    }
  }
};
</script>
